import React, { createContext, useContext, useEffect, useState } from "react";
import { useTronContexts } from "./TronContexts";
import BigNumber from "bignumber.js";
import { formatToken } from "helpers/formatToken";
import { ABI_RESOURCE } from "configs/constants/abi";
import { useWallet } from "@tronweb3/tronwallet-adapter-react-hooks";
const CONTRACT_RESOURCES = process.env.REACT_APP_CONTRACT_RESOURCES ?? "";

export const ResourceContext = createContext({
  isLoading: false,
  energyAvailable: 0,
  bandwidthAvaiable: 0,
  priceEnergy: 0,
  priceTRX: 0,
  frozenBandwidth: 0,
  frozenEnergy: 0,
  totalEnergyResource: 0,
  //balance account
  energy: 0,
  totalEnergy: 0,
  bandwidth: 0,
  totalBandwidth: 0,
  getBalanceResource: (address: string) => {},
});
const ResourceContextWrap = ({ children }: { children: React.ReactNode }) => {
  const { address } = useWallet();
  const { tronWeb } = useTronContexts();
  const [isLoading, setIsLoading] = useState(true);
  const [addressResource, setAddressResource] = useState({
    energyAvailable: 0,
    bandwidthAvaiable: 0,
    priceEnergy: 0,
    priceTRX: 0,
    frozenBandwidth: 0,
    frozenEnergy: 0,
    totalEnergyResource: 0,
    //balance account
    energy: 0,
    totalEnergy: 0,
    bandwidth: 0,
    totalBandwidth: 0,
  });
  const {
    energyAvailable,
    priceEnergy,
    frozenBandwidth,
    frozenEnergy,
    priceTRX,
    bandwidthAvaiable,
    totalEnergyResource,
    energy,
    totalEnergy,
    bandwidth,
    totalBandwidth,
  } = addressResource;
  const getBalanceResource = async (address: string) => {
    if (!tronWeb.isAddress(address)) {
      return;
    }
    try {
      const [infoResource, infoAccount, instance] = await Promise.all([
        tronWeb.trx.getAccountResources(address),
        tronWeb.trx.getAccount(address),
        tronWeb.contract(ABI_RESOURCE, CONTRACT_RESOURCES),
      ]);
      let arrAvaiTronDelegate = await instance
        .getDelegatableResources([address], 1)
        .call();
      let avaiTronDelegate = 0;
      if (arrAvaiTronDelegate.length > 0) {
        avaiTronDelegate = arrAvaiTronDelegate[0];
      }
      if (infoResource && infoAccount) {
        const { TotalEnergyLimit, TotalEnergyWeight } = infoResource;
        const { frozenV2 } = infoAccount;
        const valuePriceEnergy = +new BigNumber(TotalEnergyLimit ?? 0)
          .div(TotalEnergyWeight ?? 1)
          .toFixed(2);
        const valuePriceTRX = new BigNumber(TotalEnergyWeight ?? 0)
          .div(TotalEnergyLimit ?? 1)
          .toNumber();
        const frozenBandwidth =
          formatToken({ type: "devided", value: +frozenV2?.[0].amount }) || 0;
        const frozenEnergy =
          formatToken({ type: "devided", value: +frozenV2?.[1].amount }) || 0;
        const energyAvailable = +new BigNumber(
          formatToken({ type: "devided", value: avaiTronDelegate })
        )
          .multipliedBy(valuePriceEnergy)
          .toFixed(2);
        const powerLimit = new BigNumber(infoResource?.tronPowerLimit || 0)
          .minus(
            formatToken({
              type: "devided",
              value: infoAccount?.delegated_frozen_balance_for_bandwidth ?? 0,
            })
          )
          .minus(
            formatToken({
              type: "devided",
              value: infoAccount?.delegated_frozenV2_balance_for_bandwidth ?? 0,
            })
          )
          .minus(
            formatToken({
              type: "devided",
              value: infoAccount?.frozenV2?.[0]?.amount ?? 0,
            })
          )
          .toNumber();
        const totalEnergyResource = new BigNumber(powerLimit)
          .multipliedBy(valuePriceEnergy)
          .toNumber();
        //balance
        setAddressResource({
          ...addressResource,
          priceEnergy: valuePriceEnergy,
          priceTRX: valuePriceTRX,
          energyAvailable,
          frozenBandwidth,
          frozenEnergy,
          totalEnergyResource,
          //balance
          energy: infoResource.EnergyLimit - (infoResource.EnergyUsed || 0),
          totalEnergy: infoResource.EnergyLimit,
          bandwidth:
            infoResource.freeNetLimit -
            (infoResource.freeNetUsed || 0) +
            (infoResource.NetLimit || 0) -
            (infoResource.NetUsed || 0),
          totalBandwidth:
            infoResource.freeNetLimit + (infoResource.NetLimit || 0),
        });
        setIsLoading(false);
      }
    } catch (error) {
      console.log("error get balance trx", error);
      setIsLoading(false);
    }
  };
  useEffect(() => {
    if (address) {
      const timer = setTimeout(() => {
        getBalanceResource(address);
      }, 4000);
      return () => {
        clearTimeout(timer);
      };
    }
  }, [address, tronWeb]);
  return (
    <ResourceContext.Provider
      value={{
        isLoading,
        energyAvailable,
        priceEnergy,
        frozenBandwidth,
        frozenEnergy,
        priceTRX,
        bandwidthAvaiable,
        totalEnergyResource,
        energy,
        totalEnergy,
        bandwidth,
        totalBandwidth,
        getBalanceResource,
      }}
    >
      {children}
    </ResourceContext.Provider>
  );
};

export default ResourceContextWrap;
export const useResourceContext = () => useContext(ResourceContext);
