import { IErrorRLS } from "./splitError";

// eslint-disable-next-line import/no-anonymous-default-export
export default {
    "RLS000": "UNEXPECTED ERROR",
    "RLS001": "SUCCESS",
    "RLS101": "AUTHORIZATION REQUIRED",
    "RLS102": "HEADER ADDRESS REQUIRED",
    "RLS104": "SIGNATURE OUTDATE",
    "RLS105": "NOT SUPPORT",

    "RLS200": "SIGNATURE INVALID",
    "RLS201": "DOMAIN NOT EXIST",
    "RLS202": "MUST APPROVE FIRST",
    "RLS203": "INSUFFICIENT WTRX",
    "RLS204": "INVALID API KEY",
    "RLS205": "INSUFFICIENT BALANCE",
    "RLS206": "INVALID PAYMENT METHOD",
    "RLS212": "ADDRESS INVALID",
    "RLS213": "DURATION INVALID",
    "RLS214": "AlREADY MINT WTRX",
    "RLS215": "USER HAS SET PERMIT",
    "RLS216": "PERMISSION INVALID",
    "RLS217": "USER PERMISSION NOT EXIST",
    "RLS218": "TRANSACTION ID ALREADY EXISTS",
    "RLS219": "NON OWNER TRANSACTION",
    "RLS220": "ORDER REQUEST NOT EXISTS",
    "RLS221": "FEE LIMIT TOO HIGH",
    "RLS222": "ORDER REQUEST CAN NOT CANCEL",
    "RLS223": "PARAMS NOT MATCH TRANSACTION",
    "RLS224": "REF CODE ALREADY EXIST",
    "RLS225": "ORDER BUY ENERGY CAN NOT CREATE",
    
    "RLS400": "MISSING PARAMS",
    "RLS401": "INVALID PAGE",
    "RLS402": "INVALID PAGESIZE",
    "RLS403": "INVALID VALID UNTIL TIME",
    "RLS405": "INVALID VALUE",
    "RLS406": "USER NOT EXIST",
    "RLS408": "INVALID PARAMS",
    "RLS409": "PROJECT NOT EXISTS",
    "RLS410": "PROJECT NAME EXISTS",
    "RLS412": "TRANSACTION IS NOT VALID TRANSFER TRX",
    "RLS413": "RATE LIMIT",
    "RLS414": "MIN TRANSFER AMOUNT",
    "RLS415": "ABI INVALID",
    "RLS416": "RECIPIENT CONTRACT NOT EXISTS",
    "RLS417": "RECIPIENT CONTRACT ALREADY EXISTS",
    "RLS418": "RECIPIENT CONTRACT INVALID",
    "RLS419": "METHOD CALLING IS NOT SUPPORT",
    "RLS420": "TRANSACTION IS NOT DELEGATE RESOURCE",
    "RLS421": "TRANSACTION IS NOT VALID DELEGATE RESOURCE",
    "RLS422": "NOT SUPPORT PARTIAL ORDER",
    "RLS423": "ORDER IS FULLED",
    "RLS424": "DELEGATED FOR USER NOT AVAILABLE",
    "RLS425": "FAST REQUEST",
    "RLS426": "SINGEDTX EXPIRED",
    "RLS427": "REF CODE INVALID",
    "RLS428": "USER NOT ENOUGH COMMISSION",
    "RLS429": "USER ALREADY EXISTS",
    "RLS430": "TRANSACTION IS NOT VALID TRANSFER TRC10",
    "RLS431": "DELEGATE RESOURCE AMOUNT OUT OF RANGE",
    "RLS432": "DELEGATE RESOURCE LOCK PERIOD NOT MATCHED",

    "RLS501": "SERVER MAINTAINED",
    "RLS502": "BOT INSUFFICIENT BALANCE",
    "RLS228": "YOU NEED TO WAIT 1 HOUR BETWEEN TWO EDITS"
} as IErrorRLS