import TextError from "components/core/TextError";
import WrapInput from "components/core/WrapInput";
import React, { forwardRef, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  InputAttributes,
  NumericFormat,
  NumericFormatProps,
} from "react-number-format";
import styled from "styled-components";
import OrderBook from "./OrderBook";
type InputPriceMarketProps = {
  value: string;
  errPrice: boolean;
  minPrice: number | null;
  minBuyEnergyPrice: any;
  targetAddress: string;
  minDelegate: number | null
} & NumericFormatProps<InputAttributes>;
const InputPriceMarket = forwardRef(
  (
    {
      value,
      errPrice = false,
      minBuyEnergyPrice,
      minPrice,
      targetAddress,
      minDelegate,
      ...props
    }: InputPriceMarketProps,
    ref
  ) => {
    const { t } = useTranslation();
    const [isOpenOrderBook, setIsOpenOrderBook] = useState(false);
    const handleOrderBook = (e: boolean) => {
      setIsOpenOrderBook(e);
    };
    return (
      <Wrap>
        <WrapInput className="elirc-input">
          <div className="mb-input">
            <NumericFormat
              {...props}
              getInputRef={ref}
              inputMode="decimal"
              allowedDecimalSeparators={[",", "."]}
              value={value}
              //   onValueChange={(values: any) => {
              //     handleChange(values.value, "price");
              //   }}
              thousandSeparator={true}
              placeholder={t("price")}
              className="size-21 color-white"
              allowNegative={false}
              decimalScale={2}
              onFocus={() => handleOrderBook(true)}
              onBlur={() => handleOrderBook(false)}
            />
          </div>
          <span className="size-21 color-white mb-unit">SUN</span>
        </WrapInput>
        {/* {errPrice && (
          <TextError
            text={`${t("minPriceErr")}${minPrice || minBuyEnergyPrice}`}
          />
        )} */}
        {isOpenOrderBook && (
          <WrapOrder className="ipm-order">
            <div className="order-content">
              <p className="size-22 color-green ipm-order-title">
                {t("orderBook")}
              </p>
              <OrderBook value={value} targetAddress={targetAddress} minDelegate={minDelegate}/>
            </div>
          </WrapOrder>
        )}
      </Wrap>
    );
  }
);

export default InputPriceMarket;
const Wrap = styled.div`
  position: relative;
  z-index: 11;
`;
const WrapOrder = styled.div`
  position: absolute;
  width: 100%;
  background: linear-gradient(
    -45deg,
    rgb(140, 245, 168) 0%,
    rgba(193, 255, 210, 0.063) 50%,
    rgb(140, 245, 168) 100%
  );
  padding: 1px;
  border-radius: 10px;
  /* min-height: 100px; */
  width: 260px;
  bottom: calc(100% + 10px);
  .order-content {
    background-color: rgb(37, 42, 38);
    border-radius: 10px;
    position: relative;
    width: 100%;
    padding: 10px 15px;
    .ipm-order-title {
      text-align: center;

      /* margin-bottom: 10px; */
    }
  }
`;
