import BigNumber from "bignumber.js";
import WarningAlert from "components/core/WarningAlert";
import { breakpointsMedias } from "configs/constants/breakpointMedias";
import { formatCustom } from "helpers/formatNumber";
import { convertSecondToTimer } from "helpers/ultilities/convertSecondToTimer";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
const TIME_ONE_DAY = 24 * 60 * 60 * 1000;
const MIN_DELEGATE = 100000;

type SelectionErrorProps = {
  errors: string[];
  availableExtendAt: number;
  extendMinTimeTo: number;
};
const SelectionError = ({
  errors = [],
  availableExtendAt = 0,
  extendMinTimeTo = 0,
}: SelectionErrorProps) => {
  const { t } = useTranslation();
  if (!errors.length) {
    return null;
  }
  const detectError = (
    text: string,
    availableExtendAt: number,
    extendMinTimeTo: number
  ) => {
    const dateNow = new Date().getTime();
    switch (text) {
      case "not_in_time_extend":
        if (dateNow < availableExtendAt) {
          const timerCounter = new BigNumber(availableExtendAt - dateNow)
            .div(1000)
            .toFixed(0);
          const { days, hours, minutes, second } = convertSecondToTimer(
            +timerCounter
          );
          return t("availableInTime", {
            value: `${
              +days > 0 ? `${days} ${+days > 1 ? "days" : "day"}, ` : ""
            } 
            ${+hours > 0 ? `${hours} hours, ` : ""} 
            ${+minutes > 0 ? `${minutes} minutes ` : ""}
            ${+second > 0 ? `${second} minutes ` : ""}`,
          });
        }
        if (dateNow < extendMinTimeTo) {
          return t("minDurationIsTime", {
            value: `${Math.ceil(
              (extendMinTimeTo - dateNow) / TIME_ONE_DAY
            ).toFixed(0)} days`,
          });
        }
        return t("not_in_time_extend");
      case "extra_buy_too_small": {
        return t("extra_buy_too_small", {
          value: formatCustom(MIN_DELEGATE, "0,0"),
        });
      }

      default:
        return t(text);
    }
  };
  return (
    <Wrap>
      <div className="sec-heading">
        <WarningAlert type="error" className="sec-heading__error">
          <p className="size-1 color-white dropdown-title">{t("error")}</p>
        </WarningAlert>
      </div>
      <div className="sec-errors">
        {[...errors].map((error, index) => (
          <p key={index} className="size-1 color-red text-center">
            {detectError(error, availableExtendAt, extendMinTimeTo)}
          </p>
        ))}
      </div>
    </Wrap>
  );
};

export default SelectionError;
const Wrap = styled.div`
  .sec-heading {
    display: flex;
    justify-content: center;
    margin-bottom: 10px;
  }
  .sec-errors {
  }
  ${breakpointsMedias.max767} {
    display: flex;
    justify-content: space-between;
    align-items: center;
    .sec-heading {
      margin-bottom: 0;
      &__error {
        margin-top: 0;
      }
    }
  }
`;
