interface IKey {
  address: string;
  weight: number;
}
export interface IPermissions {
  type: number;
  keys: IKey[];
  operations: number[] | string;
  namePermission: string;
  threshold: number;
  // type: "Owner" | "Active" | "Witness"
}
export enum EResource {
  banwidth = "BANDWIDTH",
  energy = "ENERGY",
}
export enum EStatusPermission {
  PASS = "pass",
  ACTIVE = "active",
  NEXT = "next",
}
//extend
export type TExtendAndBuyInput = {
  delegator: string;
  is_extend: boolean;
  extra_amount: number;
  extend_to: number;
};
export type TDelegateDetail = {
  id: string;
  type: "extend" | "buyMoreEnergy";
  energy: number;
  timeFrom: number;
  timeTo: number;
  price: number;
  trxPaid: number;
  duration: number;
};
export type TTransactionDetail = {
  id: string;
  address: string;
  amount: number;
  duration: number;
  unlockAt: number;
  price: number;
  payout: number;
};
export type TFieldSortExtend = "amount" | "exprire" | "price";
export type TTypeSort = "asc" | "desc";
