import { useLazyQuery } from "@apollo/client";
import ResourceValueType from "components/core/ResourceValueType";
import TextToolTipRename from "components/core/TextTooltipRename";
import { GET_EXTENDABLE_DELEGATE_QUICK } from "configs/apolloSchema";
import { useResourceContext } from "contexts/ResourceContext";
import { formatCustom } from "helpers/formatNumber";
import { useEffect, useMemo } from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { useSystemExtendTeleContext } from "../../../contexts/SystemExtendTeleContext";
import { useTeleContext } from "../../../contexts/TeleContext";
const TIME_ONE_DAY = 24 * 60 * 60 * 1000;

const EnergyInfo = () => {
  const { apiKey, requester, balance } = useTeleContext();
  const address = requester;
  const { energy, totalEnergy, getBalanceResource, isLoading } =
    useResourceContext();
  const { receiverAddress } = useSystemExtendTeleContext();
  const { t } = useTranslation();
  const [getExtendDelegate, { data, loading }] = useLazyQuery(
    GET_EXTENDABLE_DELEGATE_QUICK
  );
  useEffect(() => {
    if (receiverAddress) {
      getBalanceResource(receiverAddress);
    }
  }, [receiverAddress]);
  //calc energy buying
  useEffect(() => {
    if (address && receiverAddress) {
      getExtendDelegate({
        variables: {
          requester: address,
          receiver: receiverAddress,
          resourceType: "ENERGY",
          suggestData: {
            max_price: 0,
            extend_to: new Date().getTime() + TIME_ONE_DAY,
            extra_buy_amount: 0,
            extend_amount: 0,
          },
        },
      });
    }
  }, []);
  console.log({ data, address, receiverAddress });
  const { totalExtendAmount } = useMemo(() => {
    const delegates = data?.rls_get_extendable_delegates?.data ?? [];
    const sumExtendAmount =
      delegates.length > 0
        ? [...delegates].reduce((acc: number, item) => {
            acc += item.amount ?? 0;
            return acc;
          }, 0)
        : 0;
    return {
      totalExtendAmount: sumExtendAmount,
    };
  }, [data]);
  return (
    <Wrap>
      <div className="eic-item">
        <p className="size-21 color-white">{t("availableEnergy")}</p>
        <div className="eic-item__energy">
          <p className="size-22 color-white">{formatCustom(energy)} /</p>
          {!isLoading ? (
            <ResourceValueType value={totalEnergy} />
          ) : (
            <p className="size-21 color-white">--</p>
          )}
        </div>
      </div>
      <div className="eic-item">
        <TextToolTipRename
          text="buyingOnTronsave"
          tooltipContent="buyingOnTronsaveTooltip"
        />
        {/* <p className="size-21 color-white">{t("buyingOnTronsave")}</p> */}
        {!loading ? (
          <ResourceValueType value={totalExtendAmount} />
        ) : (
          <p className="size-21 color-white">--</p>
        )}
      </div>
    </Wrap>
  );
};

export default EnergyInfo;
const Wrap = styled.div`
  .eic-item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
    margin-bottom: 5px;
    gap: 5px;
    &:last-child {
      margin-bottom: 0;
    }
    &__energy {
      display: flex;
      align-items: center;
      gap: 5px;
    }
  }
`;
